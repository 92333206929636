import React from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import Login from './pages/Auth/Login/Login';
import ResetPassword from './pages/Auth/ResetPassword/ResetPassword';
import SetPassword from './pages/Auth/SetPassword/SetPassword';
import WaitingPage from '@izi-logistics/common/lib/pages/WaitingPage';
import RootRoutes from './RootRoutes';
import { client, URL } from './api/apolloClient';
import { GlobalNotificationsSocket, GlobalSocketClient } from '@izi-logistics/common/lib/components/Socket';
import { GlobalChannelSocket } from '@izi-logistics/common/lib/pages/Chat/stores/Channel';

export default ({ store }) => {
  const accountStore = store.accountStore;
  if (accountStore.isWatingScreen) {
    return (
      <Switch>
        <Route path="*" component={WaitingPage} />
      </Switch>
    );
  }
  return accountStore.isLoggedIn ? (
    <GlobalSocketClient config={{ URL, client }}>
      <GlobalNotificationsSocket>
        <GlobalChannelSocket client={client}>
          <Switch>
            <Route path="/change-password" component={SetPassword} />
            <Route path="/" component={RootRoutes} />
          </Switch>
        </GlobalChannelSocket>
      </GlobalNotificationsSocket>
    </GlobalSocketClient>
  ) : (
    <Switch>
      <Route exact path="/login" component={Login} />
      <Route path="/change-password" component={SetPassword} />
      <Route exact path="/reset-password" component={ResetPassword} />
      <Route path="*">
        <Redirect to="/login" />
      </Route>
    </Switch>
  );
};
